import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
/** lsandoval: 11/01/2022 We add factura.green custom routes */
import fg from './routes/fg'
import apps from './routes/apps'
//import dashboard from './routes/dashboard'
//import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
//import chartsMaps from './routes/charts-maps'
//import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'fg-home' } },
    ...fg,
    ...apps,
    //...dashboard,
    ...pages,
    //...chartsMaps,
    //...formsTable,
    //...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!isLoggedIn && (to.name !== 'auth-login' && to.name !== 'auth-register' && to.name !== 'auth-register-partner')) {
    return next({ name: 'auth-login' })
  }
  // lsandoval : 26/02/2022 While implement correct ACL into factura.green
  //if (!canNavigate(to)) {
  if (false) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const session = getUserData()
    next(getHomeRouteForLoggedInUser(session ? session.account.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
